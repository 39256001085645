@font-face {
  font-family: "joan";
  src: url("../Fonts/Joan/Joan-Regular.ttf");
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.navbar__content {
  font-family: "joan";
  display: flex;
  height: 10vh;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
.logo_img {
  width: 200px;
  min-width: 200px;
  height: 50px;
  min-height: 50px;
  background-image: url(https://cdn.cloudflare.steamstatic.com/apps/dota2/images/dota_react/global/dota2_logo_horiz.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0.8;
  margin-right: 40px;
  margin-left: 10px;
  cursor: pointer;
  animation-name:opacityChanger ;
  animation-duration: 600ms;
  animation-timing-function: ease-in-out;
}
.logo_img:hover {
  opacity: 1;
}
.navbar__items {
  display: flex;
  min-width: 500px;
  justify-content: space-around;
}
.navbar__items > *{
  list-style: none;
}
.navbar__link {
  font-size: 25px;
  color: #cccccc;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
}
.navbar__link:hover {
  color: #fff;
}
.navbar__item:first-child{
  animation-name: leftToRight;
  animation-duration: 600ms;
  animation-timing-function: ease-out;
}
.navbar__item:nth-child(2){
  animation-name: topToBottom;
  animation-duration: 600ms;
  animation-timing-function: ease-out;
}
.navbar__item:last-child{
  animation-name: rightToLeft;
  animation-duration: 600ms;
  animation-timing-function: ease-out;
}
/* Key Frames */
@keyframes leftToRight{
  0%{
    transform: translateX(-1000%);
  }
  100%{
    transform: translateX(0%);
  }
}
@keyframes rightToLeft{
  0%{
    transform: translateX(1000%);
  }100%{
    transform: translateX(0%);
  }
}
@keyframes topToBottom{
  0%{
    transform: translateY(1000%);
  }100%{
    transform: translateY(0%);
  }
}
@keyframes opacityChanger{
  0%{
    opacity: 0;
  }100%{
    opacity: 1;
  }
}

/* MEDIA QUERIES */
@media (max-width: 1200px) {
  .logo_img {
    width: 50px;
    height: 50px;
    min-width: 100px;
    min-height: 100px;
  }
  .navbar__link{
      font-size: 15px;
  }
  .navbar__items{
    min-width: 200px;
  }
}

@media (max-width: 768px){
  .navbar__items{
    min-width: 200px;
  }
  .navbar__content{
    justify-content: center;
  }
    .logo_img{
        display: none;
    }
    .navbar__link{
        font-size: 10px;
    }
}

