@font-face {
  font-family: "noto-sans";
  src: url("../Fonts/Noto_Sans/NotoSans-Regular.ttf");
}
@font-face {
  font-family: "joan";
  src: url("../Fonts/Joan/Joan-Regular.ttf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.main {
  background: linear-gradient(#191e24, #c1c6ce);
  max-width: 100vw;
  width: 100%;
  min-height: 100vh;
}
.page__container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  padding: 80px 50px;
  max-width: 1500px;
  margin: auto;
  width: 100%;
}
.heroes__container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  
}
.heroes__header {
  font-family: "joan";
  text-align: center;
  font-size: 7vw;
  color: white;
  cursor: default;
  margin-bottom: 20px;
  animation-name: opacityChanger;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
}

/* MEDIA QUERIES */

@media(min-width:768.2px) and (max-width:1024px){
    .heroes__container {
        justify-content: space-evenly;
      }
}

@media (min-width: 480.2px) and (max-width: 768px) {
  .page__container {
    padding: 10px 5px;
  }
  .heroes__container {
    justify-content: space-around;
  }
}


@media (max-width: 480px) {
    .page__container {
      padding: 10px 5px;
    }
    .heroes__container {
      justify-content: space-around;
    }
  }

  /* KEYFRAMES */

  @keyframes opacityChanger{
    0%{
      opacity: 0;
    }100%{
      opacity: 1;
    }
  }
