.header_container {
  display: flex;
  flex-direction: column;
}
.header__primary {
  display: flex;
  align-items: center;
}

.header__primary > * {
  color: #fff;
  margin-right: 5px;
  font-family: "noto-sans";
  font-size: 24px;
}
.header__name {
  font-size: 60px;
  line-height: 62px;
  margin-top: 12px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #fff;
}
@media (max-width: 768px) {
  .header__name {
    font-size: 40px;
    line-height: 40px;
  }

  .header__primary > * {
    color: #fff;
    margin-right: 5px;
    font-family: "noto-sans";
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .header__name {
    font-size: 30px;
    line-height: 30px;
  }

  .header__primary > * {
    color: #fff;
    margin-right: 5px;
    font-family: "noto-sans";
    font-size: 12px;
  }
}
