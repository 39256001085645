

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.hero_link{
  animation: 1s ease-in-out  1 makeVisible;
  animation-fill-mode: forwards;

}
.hero_link:hover{
  position: relative;
  z-index: 5;
}

.img__container {
  width: 256px;
  height: 144px;
  margin: 8px 8px;
  cursor: pointer;
  border-bottom: 1px solid;
  border-right: 1px solid black;
}
.img__container:hover {
  transform: scale(1.2);
  transition: all ease 300ms;
  box-shadow: 24px 5px 29px -1px rgba(0, 0, 0, 0.91);
  -webkit-box-shadow: 24px 5px 29px -1px rgba(0, 0, 0, 0.91);
  -moz-box-shadow: 24px 5px 29px -1px rgba(0, 0, 0, 0.91);
}
.img__container:not(:hover) {
  transform: scale(1);
  transition: all ease 300ms;
}
.hero__img {
  width: 256px;
  height: 144px;
  object-fit: contain;
  -webkit-backface-visibility: hidden;
  -ms-transform: translateZ(0);
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.hero__img:hover {


  transition: all ease 300ms;
}

@media(min-width:768.2px) and (max-width:1024px){
  .hero_link{
    width: 200px;
    height: 112px;
    margin-bottom: 15px;
  }
  .img__container{
    width: 100%;
    height: 100%;
    border-bottom: none;
    margin: 0;
  }
  .hero__img{
    width: 100%;
    height: 100%;
  }
}

@media(min-width:480.2px) and (max-width:768px){
  .hero_link{
    width: 200px;
    height: 112px;
    margin-bottom: 15px;
  }
  .img__container{
    width: 100%;
    height: 100%;
    border-bottom: none;
    margin: 0;
  }
  .hero__img{
    width: 100%;
    height: 100%;
  }
}



@media(max-width:480px){
  .hero_link{
    width: 80px;
    height: 45px;
    margin-bottom: 5px;
  }
  .img__container{
    width: 100%;
    height: 100%;
    border-bottom: none;
    margin: 0;
  }
  .hero__img{
    width: 100%;
    height: 100%;
  }
}
@keyframes makeVisible{
  0%{
    opacity: 0;
  }100%{
    opacity: 1;
  }
}

